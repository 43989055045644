$height: 158px;
$width: 355px;
$width1: 469px;
.x-1 {
  position: absolute;
  width: $width1;
  height: $height;
  animation: x1 3.6s linear infinite alternate !important;
  z-index: 1;
}

.y-1 {
  width: $width1;
  height: $height;
  animation: y 4s linear infinite alternate !important;
  z-index: 1;
}

.x-2 {
  position: absolute;
  width: $width;
  height: $height;
  animation: x 2.6s linear infinite alternate !important;
  z-index: 1;
}

.y-2 {
  width: $width;
  height: $height;
  animation: y 5s linear infinite alternate !important;
  z-index: 1;
}

@keyframes x {
  100% {
    transform: translateX(calc(100vw - #{$width}));
  }
}

@keyframes x1 {
  100% {
    transform: translateX(calc(100vw - #{$width1}));
  }
}

@keyframes y {
  100% {
    transform: translateY(calc(100vh - #{$height}));
  }
}

.bounce-content .btn {
  background: none;
  @extend .smooth-transition;
  text-transform: uppercase;
  text-shadow: 0px 0px 12px #000;
  font-family: $special-font-family;
}

.home-cta {
  text-align: center;
}

.massive-text { 
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
 
}

.massive-text-1 .btn {
  --minFontSize: 32px;
  --maxFontSize: 350px;
  --scaler: 13vw;
  font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) ) !important;
  line-height: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) ) !important;
}

.massive-text-2 .btn {
  --minFontSize: 32px;
  --maxFontSize: 350px;
  --scaler: 17vw;
  font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) ) !important;
  line-height: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) ) !important;
}


.massive-text .btn:hover {
  transform: scale(1.03);
  color: #000;
  text-shadow: 0px 0px 20px #FFF;

}
 
@media (max-width: $tablet) {
  //.massive-text { transform:  translate(-50%, -50%) rotate(90deg);;}

}

