.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-item {
  cursor: pointer;
}

.tooltip-prompt {
  position: fixed;
  opacity: 0;
  z-index: 1;
  background-color: $dark-grey;
  color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  font-size: 12px;
}

.tooltip-shadow {
  position: absolute;
  height: 1.5em;
  width: 1.9em;
}

.tooltip-shadow.top {
  width: 100%;
  bottom: 90%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-shadow.bottom {
  width: 100%;
  top: 90%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-shadow.left {
  height: 100%;
  top: 50%;
  transform: translate(0, -50%);
  right: 90%;
}

.tooltip-shadow.right {
  height: 100%;
  top: 50%;
  transform: translate(0, -50%);
  left: 90%;
}

.tooltip-prompt.tooltip-show {
  position: absolute;
  opacity: 1;
  z-index: 3;
}

.tooltip-prompt.tooltip-show.tooltip-arrow:after {
  content: "";
  position: absolute;
  width: 0;
  border: 10px solid;
  border-color: rgba(136, 183, 213, 0);
  border-width: 5px;
  border-bottom-color: $dark-grey;
}
.tooltip-prompt.bottom.tooltip-show.tooltip-arrow:after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-prompt.top.tooltip-show.tooltip-arrow:after {
  top: 99%;
  left: 50%;
  transform: translate(-50%, 0);
}

.tooltip-prompt.left.tooltip-show.tooltip-arrow:after {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.tooltip-prompt.right.tooltip-show.tooltip-arrow:after {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.tooltip-hide {
  z-index: -1 !important;
}

.tooltip-background-hide {
  display: none;
}
.info-tooltip {
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $dark-grey;
  font-size: 15px;
  font-weight: 900;
}
.info-tooltip:after {
  content: "\f05a";
}

@media (max-width: $mobile) {
  .tooltip-prompt {
    position: fixed !important;
    width: 100vw !important;
    top: auto !important;
    bottom: -100% !important;
    right: 0vw !important;
    left: 0vw !important;
    transform: translate(0, 0) !important;
  }

  .tooltip-prompt.tooltip-show {
    bottom: 0 !important;
    transition: bottom 0.3s;
  }
  .tooltip-prompt.tooltip-arrow:after {
    border: none !important;
  }

  .tooltip-background-show {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 1;
  }
}
