/********** START FONT **********/

.global-font {
  font-family: $global-font-family;
  @include themes($themes) {
    font-family: themed("special-font-family");
  }
  font-size: $global-font-size;
  line-height: $global-line-height;
  font-weight: 400;
  
  // @media (max-width: $mobile) {
  //   font-size: $small-font-size;
  // }
}
.headers {
  @include themes($themes) {
    font-family: themed("special-font-family");
  }
  margin: 0 0 $global-margin * 2 0;
}
@include themes($themes) {
  font-family: themed("global-font-family");
}

h1,
.h1 {
  @extend .headers;
  font-size: 20px;
  line-height: 28px;
  font-weight: $font-weight-bold;
 
}
h2,
.h2 {
  @extend .headers;
  font-size: 19px;
  line-height: 23px;
  font-weight: $font-weight-bold;
 
}
h3,
.h3 {
  @extend .headers;
  font-size: 18px;
  line-height: 23px;
  font-weight: $font-weight-bold;
 
}
h4,
.h4 {
  @extend .headers;
  font-size: 16px;
  line-height: 21px;
  font-weight: $font-weight-bold;
}
h5,
.h5 {
  @extend .headers;
  font-size: 14px;
  line-height: 18px;
  font-weight: $font-weight-bold;
}
h6,
.h6 {
  @extend .headers;
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
}
p,
.p {
  @extend .global-font;
  margin: 0 0 $global-margin * 3 0;
}
small,
.small {
  font-size: $small-font-size;
  line-height: $global-line-height;
}
a {
  color: $link-color;
  text-decoration: none;
  @extend .smooth-transition-color;
}
a:hover {
  text-decoration: underline;
  color: $link-color;
}
a:focus,
a.active {
  text-decoration: underline;
  color: $link-color;
}
label {
  font-size: $global-font-size;
  line-height: $global-line-height;
  font-weight: 600;
  @media (max-width: $mobile) {
    font-size: $small-font-size;
  }
}

.title {
  padding-bottom: $global-padding;
  border-bottom: $global-border;
}
