$themes: (
  default: (
    primary-color: #cf2c29,
    active-color: #bf0d3e,
    link-color: $link-color,
    font-color: $font-color,
  ),
  onsite: (
    primary-color: #cf2c29,
    active-color: #bf0d3e,
    link-color: $link-color,
    font-color: $font-color,
  ),
);
