.smooth-transition {
  -webkit-transition: all $transition ease-in-out;
  -moz-transition: all $transition ease-in-out;
  -o-transition: all $transition ease-in-out;
  -ms-transition: all $transition ease-in-out;
    -webkit-backface-visibility: hidden;
}

.smooth-transition-color {
  transition: color $transition ease-in-out, filter $transition ease-in-out, background $transition ease-in-out, border $transition ease-in-out;
}
