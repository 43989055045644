.menu-toggle-icon {
  position: fixed;
  z-index: 3;
  right: 0;
}
.main-nav {
  position: fixed;
  height: 100vh;
  width: 0;
  max-width: 300px;
  z-index: 2;
  background: #000;
  top: 0;
  right: 0;
  @extend .smooth-transition;
}

.main-nav.show {
  width: 300px;
}
