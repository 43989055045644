/*G-INCEPTION by PMF v1.0 31.07.2014*/
/*INDEX
    0. Global
    1. All devices
    2. Tablet
    3. Mobile
*/
/*0. Global*/
.box-container {
  max-width: $desktop;
  margin: 0 auto;
  clear: left;
  overflow: visible;
  padding: 0;
}
.box-container .box-container {
  width: 100%;
}
.inner {
  margin: $global-margin;
  padding: $global-padding;
}
.box-12,
.box-11,
.box-10,
.box-9,
.box-8,
.box-7,
.box-6,
.box-5,
.box-4,
.box-3,
.box-2,
.box-1 {
  float: left;
}
.collapse {
  margin: -$global-margin;
}
.collapse-v {
  margin: -$global-margin 0 -$global-margin 0;
}
.collapse-h {
  margin: 0 -$global-margin 0 -$global-margin;
}

/*1. All devices*/
.box-12 {
  width: 100%;
}
.box-11 {
  width: 91.666665%;
}
.box-10 {
  width: 83.333332%;
}
.box-9 {
  width: 75%;
}
.box-8 {
  width: 66.666665%;
}
.box-7 {
  width: 58.333332%;
}
.box-6 {
  width: 50%;
}
.box-5 {
  width: 41.666665%;
}
.box-4 {
  width: 33.333332%;
}
.box-3 {
  width: 25%;
}
.box-2 {
  width: 16.666665%;
}
.box-1 {
  width: 8.333332%;
}
.box-hidden {
  display: none;
}
.box-show {
  display: inline-block;
}

/*2. Tablet*/
@media (max-width: $tablet) {
  .box-12,
  .box-11,
  .box-10,
  .box-9,
  .box-8,
  .box-7,
  .box-6,
  .box-5,
  .box-4,
  .box-3,
  .box-2,
  .box-1 {
    width: 100%;
  }
  .box-tablet-12 {
    width: 100%;
  }
  .box-tablet-11 {
    width: 91.666665%;
  }
  .box-tablet-10 {
    width: 83.333332%;
  }
  .box-tablet-9 {
    width: 75%;
  }
  .box-tablet-8 {
    width: 66.666665%;
  }
  .box-tablet-7 {
    width: 58.333332%;
  }
  .box-tablet-6 {
    width: 50%;
  }
  .box-tablet-5 {
    width: 41.666665%;
  }
  .box-tablet-4 {
    width: 33.333332%;
  }
  .box-tablet-3 {
    width: 25%;
  }
  .box-tablet-2 {
    width: 16.666665%;
  }
  .box-tablet-1 {
    width: 8.333332%;
  }
  .box-tablet-hidden {
    display: none;
  }
  .box-tablet-show {
    display: inline-block;
  }
}

/*3. Mobile*/
@media (max-width: $mobile) {
  .box-tablet-12,
  .box-tablet-11,
  .box-tablet-10,
  .box-tablet-9,
  .box-tablet-8,
  .box-tablet-7,
  .box-tablet-6,
  .box-tablet-5,
  .box-tablet-4,
  .box-tablet-3,
  .box-tablet-2,
  .box-tablet-1 {
    width: 100%;
  }
  .box-mobile-12 {
    width: 100%;
  }
  .box-mobile-11 {
    width: 91.666665%;
  }
  .box-mobile-10 {
    width: 83.333332%;
  }
  .box-mobile-9 {
    width: 75%;
  }
  .box-mobile-8 {
    width: 66.666665%;
  }
  .box-mobile-7 {
    width: 58.333332%;
  }
  .box-mobile-6 {
    width: 50%;
  }
  .box-mobile-5 {
    width: 41.666665%;
  }
  .box-mobile-4 {
    width: 33.333332%;
  }
  .box-mobile-3 {
    width: 25%;
  }
  .box-mobile-2 {
    width: 16.666665%;
  }
  .box-mobile-1 {
    width: 8.333332%;
  }
  .box-mobile-hidden {
    display: none;
  }
  .box-mobile-show {
    display: inline-block;
  }
}
