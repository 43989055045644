body {
  height: 100vh;
  width: 100vw;
}
.container-wrapper {
  height: 100vh;
  width: 100vw;
}
.slider-rows-container {
  height: 100%;
  @extend .smooth-transition;
}
.slider-row-container,
.slider-row,
.project-image {
  @extend .smooth-transition;
}
.project-image,
.slider-row-container {
  opacity: 1;
 } 
.slider-row-container.loading .slider-row {
  opacity: 0;
 }
 .project-image.loading{
   opacity: 0;
  // transform: translate(-50%, -50%) scale(0.8);
 }



 .spinner { 
     background: url("../../images/spinner.png") no-repeat 100% 100%;
         position: absolute;
         background-size: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50px;
    width: 50px;
    animation: fa-spin 2s linear infinite;
    margin: -25px;
 }
 
.slider-row-container.loading:after {
  content: " ";
  @extend .spinner;
 }

 .slider-row-container {
  background: #000000;
}

.overlay-container {     opacity: 1;
    pointer-events: inherit;
    overflow: hidden;
    height: 100%; 
    z-index: 15;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #000000a3;
    pointer-events: inherit; 
    -webkit-backface-visibility: hidden;
    @extend .smooth-transition;
  }
.hide-overlay .overlay-container { opacity: 0; pointer-events: none; }
 

.slide-content img {
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
}

@media (min-width: $tablet + 1) {
  .slider-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
  }

  .slider-row-container {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100vw;
    background: #000000;
  }
  .slider-row {
    height: 100%;
    overflow: hidden;
    z-index: 0;
    position: relative;
  }

  .slide {
    height: 100%;
    width: 100vw;
    float: left;
    z-index: 0;
    position: relative;
  }

  .slide-content {
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    text-align: center;
    width: 100%;
    height: 100%;
  }

 
  .next-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    width: 50%;
    background: transparent;
    height: 100%;

    cursor: url("../../images/FLECHA_HORIZONTAL.png"), auto;
  }

  .prev-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 50%;
    background: transparent;
    height: 100%;
    cursor: url("../../images/FLECHA_HORIZONTAL_IZQ.png"), auto;
  }
}

@media (max-width: $tablet) {
  html {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
  }
  .slider-container {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
  }
  .slider-rows-container {
    transform: none !important;
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;

    height: 100%;
    scroll-snap-align: start;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .slider-row-container {
    scroll-snap-align: start;
    position: relative;
    height: 100%;
  }

  .slider-row {
    transform: none !important;
    scroll-snap-type: x mandatory;
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    width: 100% !important;
    height: 100%;
  }
  .slide {
    min-width: 100vw;

    height: 100%;

    scroll-snap-align: start;
    text-align: center;
    /* float: none; */
    position: relative;
  }
  .slide-content {
    padding: 10px;
    max-width: 100%;
    height: 100%;
  }

 


  .overlay {
    position: absolute !important;
  }

  .next-button,
  .prev-button,
  .bottom-button,
  .top-button {
    display: none;
  }

  .project-footer {
    position: sticky !important;
    top: calc(100% - 35px);
  }
}

.top-button,
.bottom-button {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 3;
  transform: translateX(-50%);
  background-image: url("../../images/FLECHA_VERTICAL.png");
  height: 22px;
  width: 100px;
  margin: $global-margin 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.top-button:hover {
  background-image: url("../../images/PREVIOUS.png");
  transform: translateX(-50%) rotate(0);
}
.bottom-button {
  bottom: 0;
  top: inherit;
  transform: translateX(-50%) rotate(180deg);
  background-image: url("../../images/FLECHA_VERTICAL.png");
}
.bottom-button:hover {
  background-size: 50px;
  background-image: url("../../images/NEXT.png");
  transform: translateX(-50%) rotate(0);
}

.contact-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.about-button {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.global-container {
  min-width: 480px;
}

.nav-button:hover + .project-info,
.nav-button:focus + .project-info  { display: block;}

.project-info {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  background: #00000099;
  z-index: 3;
  /* max-width: 500px; */
  /* padding-bottom: 10px; */
  padding: 25px 60px 20px 50px;
  color: #FFF;
  z-index: 3;
  max-width: 100%;
}
.project-info .title {
  border: none;
  padding: 0;
}

.project-info .description {
  width: 560px;
  max-width: 100%;
  /* padding: 60%; */
  letter-spacing: 1.2px;
  color: $grey; 
}
 


/*sidebar*/
.sidebar-wrapper {
  height: calc(100% - #{$header-height});
  width: 200px;
  position: fixed;
  z-index: 1;
  left: 0;
  background: #2f353a;
  overflow-x: hidden;
  padding-top: 10px;
  margin: 0;
  color: white;
}
.sidebar-wrapper .sidebar {
  list-style: none;
  margin: 0;
}
.sidebar-wrapper .sidenav li {
  position: relative;
  margin: 0;
  transition: background 0.3s ease-in-out;
}
.sidebar-wrapper .sidebar .nav-link {
  display: block;
  padding: 0.75rem 1rem;
  color: #fff;
  text-decoration: none;
  background: 0 0;
  position: relative;
}
.sidebar-wrapper .sidebar .nav-item :hover,
.sidebar-wrapper .sidebar .nav-item :focus {
  color: #eee;
  background: #3a4248;
}
.sidebar-toggle {
  display: none;
  width: 100%;
  border-radius: 0;
  text-align: left;
}

@media (max-width: $tablet) {
  .sidebar-toggle {
    display: block;
  }
  .sidebar-wrapper {
    display: none;
    position: relative;
    width: 100%;
    top: 0;
  }
  .sidebar-wrapper.show {
    display: block;
  }

 
}

/*content*/
.content {
  margin-left: 200px;
  z-index: 0;
  -ms-flex: 1 1;
  flex: 1 1;
  min-width: 0;
  padding-top: $header-height;
}
.content .container-box {
  margin: 0 60px;
  padding: 60px 0;
}
@media (max-width: $tablet) {
  .content {
    margin-left: 0;
  }
  .content .container-box {
    margin: 0 $global-margin;
    padding: $global-margin 0;
  }
}

@keyframes example {
  0% {
    background-color: rgb(255, 95, 95);
  }
  33% {
    background-color: rgb(255, 255, 154);
  }
  66% {
    background-color: rgb(183, 183, 255);
  }
  100% {
    background-color: rgb(166, 255, 166);
  }
}

.bg-animation {
  background-color: red;
  animation-name: example;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.project-title {
  font-family: Butler;
  font-weight: normal !important;
  color: #fff;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 100px;
  line-height: 120px;
  left: 0;
  z-index: 2;
  transform: translateY(-50%);
  // animation: signup-response 0.5s 1;
  // -webkit-animation: signup-response 0.5s 1;
  // animation-fill-mode: forwards;

  // animation-delay: 2s;
  // -webkit-animation-delay: 1s; /* Safari and Chrome */
  // -webkit-animation-fill-mode: forwards;
}

@media (max-width: $tablet) {
  .project-title {
    font-size: 60px;
    line-height: 80px;
  }
}

// .overlay {
//   height: 100%;
//   width: 100%;
//   position: fixed;
//   content: " ";
//   display: block;
//   background: #000;
//   top: 0;
//   left: 0;
//   opacity: 0.6;
//   z-index: 1;
//   // animation: signup-response 0.5s 1;
//   // -webkit-animation: signup-response 0.5s 1;
//   // animation-fill-mode: forwards;

//   // animation-delay: 2s;
//   // -webkit-animation-delay: 1s; /* Safari and Chrome */
//   // -webkit-animation-fill-mode: forwards;
// }

@keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes signup-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}



body>svg { display: none; }


.svg-container {  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
} 

.svg-container:nth-child(2) {
  left: auto;
  right: 0;
}

.svg-container svg {  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} 

@media (max-width: $tablet) {
  
  .svg-container {  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
  } 

  .svg-container:nth-child(2) {
    top: auto;
    bottom: 0;
  }

  .svg-container svg {  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } 
}