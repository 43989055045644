.select-filter {
  position: relative;
}
.select-filter .inputs-group {
  position: relative;
  border: 1px solid #747474;
  border-radius: 0.5rem;
}

.select-filter-container {
  position: relative;
}
// .select-filter-container .select-filter-trigger {
//   @extend .input-default;
//   padding: 7.5px;
//   position: relative;
// }

.select-filter-menu {
  top: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  max-width: 100%;
  padding: 0;
  margin: 0;
  text-align: left;
  list-style: none;
  background-color: $white;
  @include themes($themes) {
    background-color: $white;
  }
  background-clip: padding-box;
  border: $global-border;
  border-radius: $border-radius;
  max-height: 200px !important;
}
.select-filter-menu .select-filter-sub-menu {
  margin: 0;
  text-align: left;
  list-style: none;
  background-color: $white;
  @include themes($themes) {
    background-color: $white;
  }
  background-clip: padding-box;
  max-height: 200px;
  overflow-y: scroll;
  border-bottom: $global-border;
}
.select-filter-menu .select-filter-menu-option {
  padding: $global-padding;
  margin: 0;
}
.select-filter-menu .select-filter-menu-option:before {
  position: relative;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Free", sans-serif;
  content: "\f0c8";
  margin-right: $global-margin;
}

.select-filter-menu .select-filter-menu-option:hover {
  background-color: $light-grey;
  color: $black;
  @include themes($themes) {
    color: $black;
  }
  cursor: pointer;
}
.select-filter-menu .select-filter-menu-option:focus {
  background-color: red;
  cursor: pointer;
}
.select-filter-menu .select-filter-menu-option.active {
  background-color: $light-grey;
  color: $black;
  @include themes($themes) {
    color: $black;
  }
}
.select-filter-menu .select-filter-menu-option.active:before,
.select-filter-menu .select-filter-menu-option:hover:before,
.select-filter-menu .select-filter-menu-option:focus:before {
  content: "\f14a";
  font-weight: 900;
  margin-right: $global-margin;
  color: $black !important;
}

.select-filter-option {
  @extend .btn;
  @extend .small;
  background: $light-grey !important;
  @include themes($themes) {
    background: $light-grey !important;
  }
  margin-top: $global-margin * 1.25;
  margin-left: $global-margin;
  margin-right: 0;
  color: $font-color !important;
}
.select-filter-container .input-group-item {
  background: $input-background;
  border-radius: $border-radius;
}
.select-filter-container .select-filter-content li {
  margin-bottom: 0;
}
.select-filter-container .select-filter-option-search {
  border-bottom: $global-border;
  padding: $global-padding;
  position: relative;
}

.select-filter-container .select-filter-icon {
  margin-left: $global-margin;
  font-family: "Font Awesome 5 Free", Arial, Helvetica, sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.select-filter-container .select-filter-icon::before {
  content: "\f107";
}

.select-filter-container .select-text-input {
  border: none;
  outline: 0;
}

.delete-icon-option {
  margin: 0 !important;
  padding: 2px !important;
  margin-left: $global-margin/2 !important;
  font-family: "Font Awesome 5 Free", Arial, Helvetica, sans-serif;
  background: $light-grey !important;
  @include themes($themes) {
    background: $light-grey !important;
  }
  color: $font-color !important;
}

.delete-icon-option:after {
  content: "\f00d";
  font-family: "Font Awesome 5 Free", Arial, Helvetica, sans-serif;
  font-weight: 900;
}

.dropdown-menu .dropdown-filter {
  min-width: 230px;
  margin-bottom: 0;
}
.dropdown-filter .select-filter .inputs-group {
  border: none;
  border-radius: 0;
}
