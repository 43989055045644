.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  // display: none;
  float: left;
  min-width: 120px;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 0;
}

.dropdown-item {
  padding: 0;
  margin: 0;
}
.dropdown-item:active {
  background-color: $light-grey;
}
.dropdown-item:last-child {
  border-bottom: none;
  margin: 0;
}
.dropdown-item a,
.dropdown-item > span {
  padding: $global-padding;
  display: block;
  text-decoration: none;
  color: $font-color;
  font-size: $small-font-size;
  cursor: pointer;
}
.dropdown-icon {
  margin-left: $global-margin;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.dropdown-icon::before {
  content: "\f106";
}
