.message {
  background: #fff;
  position: relative;
  margin-bottom: $global-margin * 2;
  // border: 1px solid $info-color;
  border-left: 8px solid $info-color;
  border-radius: $border-radius;
  color: $info-color;
  border-color: $info-color;
  overflow: hidden;
}
.message .icon {
  display: block;
  height: 100%;
  width: $global-line-height * 3;
  line-height: $global-line-height * 3;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  border-radius: $border-radius;
  color: $info-color;
}

.message .close {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}
.message .icon .fa {
  display: block;
  line-height: 50px;
  font-size: 20px;
}
.message .copy {
  padding: 15px 15px 15px 55px;
}
.message .copy p {
  margin: 0;
}
.message-info {
  color: $info-color;
  border-color: $info-color;
}
.message-info .icon {
  color: $info-color;
}
.message-warning {
  color: $warning-color;
  border-color: $warning-color;
}
.message-warning .icon {
  color: $warning-color;
}
.message-error {
  color: $error-color;
  border-color: $error-color;
}
.message-error .icon {
  color: $error-color;
}
.message-success {
  color: $success-color;
  border-color: $success-color;
}
.message-success .icon {
  color: $success-color;
}
