$default-primary-color: #000 !default;
$active-primary-color: #fff !default;

$link-color: $active-primary-color !default;

$white: #fff !default;
$light-grey: #f1f1f1 !default;
$grey: #c4c4c4 !default;
$dark-grey: #747474;
$black: #000 !default;

$blue: #0092e3 !default;
$red: #da291c !default;
$yellow: #d3a400 !default;
$green: #2d7d19 !default;
$warning-color: $yellow !default;
$success-color: $green !default;
$default-color: $dark-grey !default;
$error-color: $red !default;
$info-color: $blue !default;

$font-color: $black !default;
$input-background: #fafafa !default;

/*margins and paddings*/
$global-padding: 10px !default;
$global-margin: 10px !default;

/*border*/
$global-border: 1px solid $dark-grey !default;
$light-border: 1px solid $light-grey;
$border-radius: 0;

/*media queries breakpoints*/
$desktop: 1280px !default;
$laptop: 1024px !default;
$tablet: 960px !default;
$mobile: 640px !default;

/*fonts*/

@font-face {
  font-family: Butler;
  src: url("../fonts/Butler_Medium_Stencil.otf");
  font-display: swap;
}

@font-face {
  font-family: HelveticaNeue;
  src: url("../fonts/HelveticaNeueLTPro-Lt.otf");
  font-display: swap;
}

$global-font-family: HelveticaNeue, sans-serif !default;
$special-font-family: HelveticaNeue, sans-serif !default;
$global-font-size: 18px !default;
$global-line-height: 22px !default;
$font-weight-bold: 600;

$small-font-size: 13px !default;

$disabled-input: #fafafa;
$enabled-input: #000000;

$transition: 0.3s;

$header-height: 40px;
