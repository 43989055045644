.container-wrapper {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 1;
  position: relative;
}
.header-container {
 
  background: #000;
  padding: $global-padding;
}

.content-container {
  height: calc(100% - 116px);
  position: relative;
}

.container-wrapper:not(.home) .content-container {
  background: #000;
  color: #C4C4C4;
  @extend .smooth-transition;
}

.container-wrapper.about .content-container {
  padding-top: 30px;
  overflow: auto;
}

.container-wrapper.about .editable-content {
  max-width: 960px;
  margin: 0 auto;

}

.container-wrapper.about .editable-content p {
  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.9px;
}

.container-wrapper.about .editable-content a:hover {
  color: #000;
  text-decoration: none;
}



.container-wrapper.about .editable-content blockquote {
  margin: 0 0 0 40px !important;
  border: none !important;
  padding: 0px !important;
  margin: 0 0 0 40px;
  border: none;
  padding: 0px;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.9px;
 
}
.container-wrapper.about .editable-content blockquote p {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.9px;
}


.container-wrapper.about .hidden-image-container {
  position: relative;
}
.container-wrapper.about .hidden-image-container .hidden-image{
  @extend .smooth-transition;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  opacity: 1;
  max-width: 100%;
}
 
.container-wrapper.about .hidden-image-container .editable-content:hover + .hidden-image {
  opacity: 0;
}
.footer-container {
 
  padding:$global-padding;
  background: #000;
position: relative;
}

.project-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}


@media (max-width: $tablet) {
  
  .header-container {
    padding:$global-padding/2;
    height: 33px;
  }
  .content-container {
    height: calc(100% - 66px);
    position: relative;
  }
  .footer-container {
    padding:$global-padding/2;
    height: 33px;
  }

  .container-wrapper.about .hidden-image-container .hidden-image{
    position: relative;
    top: 0%;
    left: 0;
    opacity: 1 !important;
    transform: none;
    pointer-events: none;
    opacity: 1;
    text-align: center;
    max-width: 100%;
  }
  
}

