table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  margin: 0 0 $global-margin * 3 0 !important;
  width: 100% !important;
  border: 4px solid $light-grey !important;
  @include themes($themes) {
    border: 4px solid $light-grey !important;
  }
  max-width: 100%;
}

.table thead th {
  position: relative;
  user-select: none;
  vertical-align: middle;
}

.table th {
  white-space: nowrap;
  vertical-align: top;
  height: auto;
  font-size: $small-font-size;
}
.table td {
  vertical-align: top;
  height: auto;
  font-size: $small-font-size;
}

table td,
table th {
  border-top: 2px solid $light-grey !important;
  @include themes($themes) {
    border-top: 2px solid $light-grey !important;
  }
}

table th {
  background-color: $light-grey;
  @include themes($themes) {
    background-color: 2px solid $light-grey;
  }
  padding: $global-padding * 1.5;
}

table td {
  padding: $global-padding;
  background-color: $white;
  @include themes($themes) {
    background-color: $white;
  }
}

.table-responsive {
  overflow-x: auto;
}

.table-responsive table {
  max-width: none;
}

.search-bar-wrapper .form-control {
  border-radius: 0;
}

#cursorText {
  position: absolute;
  border: 1px solid #dee2e6;
}

.form-inline .form-check {
  justify-content: inherit;
}

th .dropdown {
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: $global-margin/2;
}
th .filter-icon {
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;

  right: 0;
  width: 15px;
  height: 10px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline;
}

th .sortable-icon {
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: $global-margin;
  margin-top: -2px;
  width: 10px;
  height: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  pointer-events: none;
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
}

th .sortable-icon:before {
  content: "\f0dd";
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
}

th .sortable-icon:after {
  content: "\f0de";
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
}

th.asc .sortable-icon:before {
  opacity: 1;
}

th.desc .sortable-icon:after {
  opacity: 1;
}

.results .list-group {
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
}

.resultsTable {
  a {
    color: black;
    text-decoration: none;
    z-index: 0;
    display: block;
    min-height: 100%;
    min-width: 100%;
    &:hover {
      text-decoration: none;
    }
    &.fullLink::after {
      display: block;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      pointer-events: auto;
      content: "";
    }
  }

  tbody {
    tr:hover {
      background-color: $dark-grey !important;
      color: white;
    }
    td {
      position: relative;
    }
  }
}

.no-data-found {
  padding: 10px;
}

table.striped-table {
  tr:nth-child(even) {
    background-color: $grey;
  }
}

table.dark-table {
  tr {
    background-color: $dark-grey;
    color: white;
  }
}
.Published {
  background-color: $green;
  @include themes($themes) {
    background-color: $green;
  }
  border-radius: 10px;
}
.Paused {
  background-color: $error-color;
  @include themes($themes) {
    background-color: $error-color;
  }
  border-radius: 10px;
}
.Archived {
  background-color: $grey;
  @include themes($themes) {
    background-color: $grey;
  }
  border-radius: 10px;
}
.Draft {
  background-color: $warning-color;
  @include themes($themes) {
    background-color: $warning-color;
  }
  border-radius: 10px;
}
.ellipsis-icon:after {
  content: "\f141";
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  font-weight: 900;
}
.edit-icon:before {
  content: "\f044";
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  margin-right: $global-margin;
}
.duplicate-icon:before {
  content: "\f0c5";
  font-size: 15px;
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  margin-right: $global-margin;
}
.pause-icon:before {
  content: "\f28b";
  font-size: 16px;
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  margin-right: $global-margin;
}
.print-icon:before {
  content: "\f02f";
  font-size: 14px;
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  font-weight: 600;
  margin-right: $global-margin;
}
.envelope-icon:before {
  content: "\f0e0";
  font-size: 15px;
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
  margin-right: $global-margin;
}
.copy-icon:before {
  content: "\f0c5";
  font-size: 11px;
  background-color: $grey;
  padding: 0.4rem;
  border-radius: 50%;
  font-family: "Font Awesome 5 Free", sans-serif;
  color: $black;
}
