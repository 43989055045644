.modal-body {
  padding: $global-padding * 2 $global-padding * 3;
  background-color: white;
  overflow-y: auto;
  overflow-x: auto;
  flex: 1 1 auto;
}
.modal-footer {
  padding: $global-padding * 2 $global-padding * 3;
  border-top: 1px solid #ccc;
}
.modal-footer > * {
  margin: 0;
}
.modal-wrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 2;
}

.modal-wrapper.modal-open {
  display: block;
}

.modal {
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50em;
  transform: translate(-50%, -100%);
  display: flex;
  max-width: 80vw;
  overflow-y: auto;
  flex-direction: column;
  max-height: 80vh;
  height: auto;
  visibility: hidden;
  z-index: 2;
}

.modal.modal-open {
  visibility: visible;
  transform: translate(-50%, -50%);
  transition: transform 0.5s;
}

.modal-header {
  position: relative;
  background-color: $light-grey;
  border-bottom: 1px solid $light-grey;
  padding: $global-padding * 3 $global-padding * 5;
}

.modal-close-icon {
  position: absolute;
  right: 1em;
  top: 1em;
  cursor: pointer;
}

.modal.fixed-right {
  top: 0;
  height: 100vh;
  max-height: 100vh;
  right: 0;
  left: auto;
  transform: translate(100%, 0);
  z-index: 2;
  transition: transform 0.5s;
}

.modal.fixed-left {
  top: 0;
  height: 100vh;
  max-height: 100vh;
  left: 0;
  right: auto;
  transform: translate(-100%, 0);
  z-index: 2;
  transition: transform 0.5s;
}

.modal.fixed-bottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  top: auto;
  max-width: 100%;
  transform: translate(0%, 100%);
  z-index: 2;
  transition: transform 0.5s;
}

.modal.fixed-top {
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  width: 100%;
  max-width: 100%;
  transform: translate(0%, -100%);
  z-index: 2;
  transition: transform 0.5s;
}

.modal.fixed-right.modal-open,
.modal.fixed-left.modal-open,
.modal.fixed-top.modal-open,
.modal.fixed-bottom.modal-open {
  transform: translate(0, 0);
  transition: transform 0.5s;
}

@media (max-width: $mobile) {
  .modal {
    width: 100%;
  }
}
