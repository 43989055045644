.logo-wrapper {
  display: inline-block;
}
.logo-wrapper img {
  display: block;
  height: 35px;
}
@media (max-width: $tablet) {
  .logo-wrapper img {
    height: 20px;
    width: auto;
  }
}

.nav-button {
  height: 35px;
  padding: 0;
  overflow: hidden;
  display: inline-block;
}
.nav-button img {
  height: 70px;

  max-height: none;
  transform: translateY(-50%);
}
.nav-button:hover img,
.nav-button:focus img,
.nav-button:active img {
  transform: translateY(-0%);
  margin-top: 0;
}

@media (max-width: $tablet) {
  .nav-button {
    height: 20px;
    width: auto;
  }
  .nav-button img {
    height: 41px;
    width: auto;
  }
}
