 
body {
  background: #000;
  margin: 0;
  font-family: $global-font-family;
  overflow: hidden;
  color: $font-color;
  // cursor: url("../images/dot-cursor.png"), auto !important;
  cursor: url("../images/dot-cursor.png"), auto !important;
}
a,
.top-button, .bottom-button {
  cursor: url("../images/dot-cursor.png"), auto !important;
}
:focus {
  outline: none;
}
ol,
ul {
  margin: 0 0 $global-margin * 2 $global-margin * 2;
  padding: 0;
}
li {
  margin-bottom: $global-margin/2;
}
img {
  max-width: 100%;
  max-height: 100%;
}
pre {
  margin-bottom: $global-margin * 2;
}
hr {
  border-bottom: 1px solid $grey;
}
.ui-section {
  margin-bottom: $global-margin * 4;
}

strong {
  font-weight: bold;
}

.editable-content i { text-align: center ; display: block; padding: 5px; }



::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #000;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }