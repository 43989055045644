canvas {
    display: block;
  }

  .world {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    @extend .smooth-transition;

  }

  
  .world.loaded {
  
    opacity: 1;
    
  }

  .dg {
    display: none;
  }