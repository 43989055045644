.editor-container {
  height: auto;
  padding: 0;
  border: none;
}
.editor-content {
  background-color: $white;
  @include themes($themes) {
    background-color: $white;
  }

  min-height: 300px;
  padding: $global-padding;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border: $global-border;
  border-top: none;
}
.editor-toolbar {
  background-color: $default-primary-color;
  @include themes($themes) {
    background-color: themed("primary-color");
  }
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.editor-commands > button {
  margin: 0;
  border-radius: 0%;
}

.editor-dropdown-container {
  display: inline-block;
}
.editor-dropdown-container .editor-dropdown {
  position: absolute;
  display: none;
  margin: 0 1px;
}
.editor-dropdown-container:hover .editor-dropdown {
  display: block;
  background: $light-grey;
  @include themes($themes) {
    background: $light-grey;
  }
  padding: $global-padding;
}

.editor-btn-tooltip > .tooltip-item > button {
  margin: 0;
  border-radius: 0%;
}

.td-table-picker {
  margin: 0;
  padding: 0;
  height: 10px;
  width: 10px !important;
  border: $global-border !important;
}
.td-table-picker-selected {
  @include themes($themes) {
    background-color: themed("active-color");
  }
}

.btn-table-picker {
  margin: 0;
  padding: 8px;
  background-color: transparent;
}

.btn-color {
  border: $global-border;
  margin-right: $global-margin/2;
  padding: $global-padding;
}

.btn-color:hover {
  filter: brightness(110%);
}

.btn-headering {
  padding: $global-padding;
  background: transparent;
  @include themes($themes) {
    color: themed("primary-color");
    border: 1px solid themed("primary-color");
  }

  margin-bottom: 3px;
}
.btn-headering:hover {
  @include themes($themes) {
    background: themed("primary-color");
  }
  color: $white;
  @include themes($themes) {
    color: $white;
  }
}

blockquote {
  margin: 0 0 0 40px !important;
  border: none !important;
  padding: 0px !important;
  margin: 0 0 0 40px;
  border: none;
  padding: 0px;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.9px;
 
}
blockquote p {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.9px;
}
